import ExcelJS from 'exceljs';
import axios from 'axios';
import moment from 'moment';

const mapping = {
  pressShop1: 'PRESS SHOP 1&2',
  pressShop2: 'PRESS SHOP 3',
  pressShop3: 'WELD SHOP'
}

export const generateExcel = async (date:any, documentType:any) => {
  try {
    const response = await axios.get(`/api/dailyProduction/excel?date=${date}&documentType=${documentType}`);
    const data = response.data.data;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Production Report');

    // Fetch and add logo
    //const imageResponse = await axios.get(
    //    'https://proxy.aqua.mushinlabs.com/proxy/https://mushin-labs.s3.ap-south-1.amazonaws.com/idea-precision/profile/idealLogo.png',
    //    { responseType: 'arraybuffer' }
    //  );
    //  const logoId = workbook.addImage({
    //    buffer: imageResponse.data,
    //    extension: 'png',
    //  });
    //  worksheet.addImage(logoId, {
    //    tl: { col: 0, row: 0 },
    //    ext: { width: 85, height: 85 }
    //  });

    // Increase height of the first row
    worksheet.getRow(1).height = 80;
    

    // Add header rows
    worksheet.mergeCells('A1:P1');
    worksheet.mergeCells('A2:P2');
    const headerCell1 = worksheet.getCell('B1');
    const headerCell2 = worksheet.getCell('A2');

    headerCell1.value = 'IDEAL PRECISION COMPONENTS Pvt. Ltd.';
    headerCell1.font = { bold: true, size: 16 };
    headerCell1.alignment = { vertical: 'middle', horizontal: 'center' };

    if(documentType === 'pressShop1'){
      headerCell2.value = `PRODUCTION REPORT ( PRESS SHOP 1&2 )`;
    }else if(documentType === 'pressShop2'){
      headerCell2.value = `PRODUCTION REPORT ( PRESS SHOP 3 )`;
    }else if(documentType === 'pressShop3'){
      headerCell2.value = `PRODUCTION REPORT ( WELD SHOP )`;
    }
    
    headerCell2.font = { bold: true, size: 14, color: { argb: 'FFFFFF' } };
    headerCell2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } };
    headerCell2.alignment = { vertical: 'middle', horizontal: 'center' };

    // Add column headers
    const headers = [
      'S. no.', 'Date','Shift','M/c No.', 'Part Name', 'Operation', 'Cycle Time', 'Operator', 
      'M/c Start Time', 'M/c End Time', 'Unscheduled / Scheduled Downtime (minutes)', 
      'Plan Qty.', 'Actual Qty.', 'Lot. No.', 'Rejection', 'Remarks'
    ];

    worksheet.addRow(headers);
    const headerRow = worksheet.getRow(3);
    headerRow.font = { bold: true };
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.eachCell((cell) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDDDDD' } };
      cell.border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
    });

    // Add data
    data.forEach((row:any, index:any) => {
      worksheet.addRow(Object.values(row));
      const dataRow = worksheet.getRow(index + 4);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
      });
    });

    // Auto-fit columns
    worksheet.columns.forEach((column, index) => {
        const widerColumns = [2, 3, 4, 6, 9, 12, 14]; // M/c No., Part Name, Operation, Operator, Unscheduled/Scheduled Downtime, Lot No., Remarks
        if (widerColumns.includes(index)) {
          column.width = 30; // 200% of 15
        } else {
          column.width = 19.5; // 130% of 15
        }
        if (column && typeof column.eachCell === 'function') {
          column.eachCell({ includeEmpty: true }, (cell) => {
            if (cell && cell.alignment) {
              cell.alignment = { ...cell.alignment, wrapText: true };
            }
          });
        }
      });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Download file
    const fileName = `Production_Report_${moment(date).format('MMMM_YYYY')}.xlsx`;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error('Error generating Excel:', error);
  }
};