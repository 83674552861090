import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend ,  BarChart, Bar, ReferenceLine} from 'recharts';
import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';
import { MultiSeriesGraphData } from '../../helpers/types';

const StyledTooltip = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  border: '1px solid #ccc',
}));

interface MultiSeriesGraphProps {
  data: MultiSeriesGraphData;
  target:any
}

const MultiSeriesGraph: React.FC<MultiSeriesGraphProps> = ({ data , target}) => {
  const colors = [
    "#ff6464",  '#FF8E8E', '#FFA8A8', '#FFC2C2', '#FFDBDB',
    '#FFB3BA', '#FFCCCB', '#FFA07A', '#FF7F50', '#FF6347'
  ];

  // Process the data into the format expected by recharts
  const chartData = data.graphData[0].data.map((_, index) => {
    const dataPoint: { [key: string]: any } = { day: index + 1 };
    data.graphData.forEach(series => {
      dataPoint[series.name] = series.data[index];
    });
    return dataPoint;
  });

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <StyledTooltip>
          <Typography variant="body2">Day: {label}</Typography>
          {payload.map((entry: any, index: number) => (
            <Typography key={index} variant="body2" style={{ color: entry.color }}>
              {`${entry.name}: ${typeof entry.value === 'number' ? entry.value.toFixed(2) : entry.value}`}
            </Typography>
          ))}
        </StyledTooltip>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={chartData} margin={{ top: 5, right: 15, left: -30, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.1)" />
        <XAxis 
          dataKey="day" 
          stroke="rgba(0,0,0,0.5)"
          tickMargin={10}
          padding={{ left: 10, right: 10 }}
          tickCount={31}
          type="number"
          domain={[1, 31]}
          ticks={[1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31]}
          interval="preserveStartEnd"
        />
        <YAxis 
          stroke="rgba(0,0,0,0.5)"
          allowDecimals={false}
        />
        <Tooltip content={<CustomTooltip />} />
        <ReferenceLine 
          y={target} 
          stroke="#ff6464"  // Make target line orange and visible
          strokeDasharray="3 3"
          strokeWidth={2}  // Bold target line
        />
        <Legend />
        {data.graphData.map((series, index) => (
          <Bar 
            key={series.name}
            dataKey={series.name}
            stackId="a"
            fill={colors[index % colors.length]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MultiSeriesGraph;







////////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////