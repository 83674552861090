import { MenuItem } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';
import moment from 'moment';

interface IProps {
  process: any;
  isView: boolean;
  partId: string;
  setRender: any;
}
const OperationSelect = ({ process, partId, isView, setRender }: IProps) => {
  const [operation, setOperation] = useState<any>([]);
  useEffect(() => {
    if (partId) {
      axios
        .get(`/api/controlPlan/controlPlan/processesAndTool/${partId}`)
        .then((res) => {
          if (res?.data) {
            const data = res.data;
            // here setting tool_id because for old production module tool_id field was not created based on process name
            if (!process?.tool_id && process?.operation_name) {
              const forSettingToolForOldRecord = data?.find(
                (op: any) => op?.process_name === process?.operation_name
              );
              process.tool_id = forSettingToolForOldRecord?.tool_id ?? null;
              console.log(process.operation_name, process.tool_id);
            }
            setOperation(res.data);
          }
        })

        .catch((err) => console.log(err));
    }
  }, [partId]);

  const fetchCycleTime = async (id:any) => {
    try{
      if(!id){
        return null;
      }
      const result = await axios.get(`/api/masterListOfTools/cycleTime/${id}`);
      console.log(result)

      return result.data.cycle_time;
    }catch(error){
      console.log(error);
    }
  }

  const calculatePlanQty = (process: any) => {
    const cycleTime = process.cycle_time || null;
    if(cycleTime === null || cycleTime ===  undefined || cycleTime === 0){
      return null
    }

    if(!process.time_start || !process.time_stop){
      return null
    }
    
    if (cycleTime && process.time_start && process.time_stop) {
      const startTime = moment(process.time_start);
      const stopTime = moment(process.time_stop);
      //const durationInHours = (stopTime.diff(startTime, 'minutes'))/60;
      //return Math.floor(durationInHours * cycleTime);
      let durationInMinutes = stopTime.diff(startTime, 'minutes');
      durationInMinutes -= ((process.total_downtime && process.total_downtime !== undefined && process.total_downtime !== null )?(process.total_downtime):(0))
      const durationInHours = (durationInMinutes)/60;
      return Math.floor(durationInHours * cycleTime);
    }
    return null;
  };

  return (
    <>
      {isView ? (
        process?.operation_name
      ) : (
        <CustomSelect
          name="operation_name"
          value={process?.operation_name || ''}
          onChange={async (e) => {
            const selectedOperation = operation?.find(
              (op: any) => op?.process_name === e.target.value
            );
            process.operation_name = e.target.value;
            process.tool_id = selectedOperation?.tool_id ?? null;
            process.cycle_time = await fetchCycleTime(process.tool_id);
            process.plan_qty = calculatePlanQty(process);
            setRender((prev: any) => !prev);
          }}
        >
          <MenuItem value="">None</MenuItem>
          {operation?.map((process: any) => (
            <MenuItem value={process?.process_name}>
              {process?.process_name}
            </MenuItem>
          ))}
        </CustomSelect>
      )}
    </>
  );
};

export default OperationSelect;
