import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DashBoardTable from '../components/Table';
import DatePickerMui from '../../../components/common/DatePickerMui';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '../../../hooks/UseQuery';




const DCTable: React.FC = () => {
    const selectedDate = useQuery('date');
    const endpoint = useQuery('endpoint')||''
  return (
    <Box sx={{ mt: 0, p: 2.3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', }}>
      </Box>
      <DashBoardTable
        date={selectedDate? selectedDate : new Date()}
        endpoint={endpoint}
        frozenColumns={1}
      />
    </Box>
  );
};

export default DCTable;