export const endpointMapping: { [key: string]:  { [subKey: string]: any }  } = {
  oeeManagementRoute: {
    main: '/common/oeeData',
    firstLevel: '/api/oeeManagementRoute/machineWise',
    firstLevelFrontendPage:'/common/OEEAnalysis',
    monthYearRouting: false,
  },
  fourMChangeDashboardGraphRoute:{
    firstLevelFrontendPage: '/common/DCTable',
    monthYearRouting: false,
    monthYearDateField: 'annual_date',
    firstLevel:'fourMChangeDashboardGraphRoute/changesForTable'
  },
  sphManagementRoute: {
    main: '/common/createTables',
    
  },
  dpManagementRoute: {
    main: '/common/createTables',
    
  },
  downTimeManagementRoute: {
    main: '/common/createTables',
    
  },
  customerComplaintManagementRoute: {
    main: '/common/createTables',
    
  },
  suppliersPPMRoute: {
    main: '/common/createIncomingCompileInspectionDashboard',
    
  },
  customerComplaintMSILGCA: {
    main: '/common/createTables',
    
  },
  rejectionGraph: {
    main: '/common/createTables',
    
  },
  supplierQualityRatingGraph: {
    main: '/common/createTables',
    
  },
  deliveryDefaultMonitoringManagementRoute: {
    main: '/common/defaultDeliveryMonitoringReport',
    
  },
  lotRejectionReportGraphManagementRoute: {
    main: '/common/createReworkLotRejectionReport',
    
  },
  energyConsumptionDashboardGraphRoute: {
    main: '/common/energyConsumption/list',
    
  },
  pendingReportsRoute: {
    main: '',
    firstLevel: 'pendingReportsRoute/pending-reports',
    firstLevelFrontendPage: '/common/dashboardData'
  },

};