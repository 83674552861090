export const apiMapping = {
    0: '/api/oeeManagementRoute/overallDayWiseOEEforDifferentPressShops/pressShop1',
    1: '/api/oeeManagementRoute/overallDayWiseOEEforDifferentPressShops/pressShop2',
    2: '/api/oeeManagementRoute/overallDayWiseOEEforDifferentPressShops/pressShop3',
    3: '/api/fourMChangeDashboardGraphRoute/changesForGraph/man',
    4: '/api/fourMChangeDashboardGraphRoute/changesForGraph/machine',
    5: '/api/fourMChangeDashboardGraphRoute/changesForGraph/material',
    6: '/api/fourMChangeDashboardGraphRoute/changesForGraph/method',
    7: '/api/pendingReportsRoute/pending-reports-for-graph',
    //8: '/api/supplierQualityRatingGraph/overallSupplierQR',
    //9: '/api/deliveryDefaultMonitoringManagementRoute',
    //10: '/api/lotRejectionReportGraphManagementRoute',
    //11: '/api/energyConsumptionDashboardGraphRoute',
  };